import { useState, useRef, useEffect, type KeyboardEvent } from 'react';
import Image from 'next/legacy/image';
import styled, { css, type Colors } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { getColor, getDuration, getSpace, getTransition } from '@yoweb/ui/styles/utils/theme';
import { pxToRem } from '@yoweb/ui/styles/utils';
import { Title, Text, Label } from '@yoweb/ui/components/typography';
import { CaretDownIcon } from '@yoweb/ui/components/Icon/CaretDownIcon';
import { CircleCheckIcon } from '@yoweb/ui/components/Icon/CircleCheckIcon';
import { theme } from '@yoweb/ui/styles';

export type ColorAccordionProps = {
  color: keyof Colors;
  contentItems: string[];
  imageAlt: string;
  imageHeight: number;
  imageSrc: string;
  imageWidth: number;
  isFocused?: boolean;
  number: number;
  onFocus?: () => void;
  onKeydown?: (event: KeyboardEvent) => void;
  text: string;
  title: string;
} & DataTestId;

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
};

const transitions = {
  duration: theme.transitionDuration.short / 1000,
  ease: theme.transitionPoints.easeInOut,
};

const ColorAccordion = ({
  color,
  contentItems,
  imageAlt,
  imageHeight,
  imageSrc,
  imageWidth,
  isFocused,
  number,
  onFocus,
  onKeydown,
  text,
  title,
  ...props
}: ColorAccordionProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const testid = props['data-testid'] ?? 'color-accordion';

  const toggle = () => setCollapsed((prevState) => !prevState);

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (onKeydown) {
      onKeydown(event);
    }
  };

  useEffect(() => {
    if (!toggleButtonRef.current) {
      return;
    }

    if (isFocused) {
      toggleButtonRef.current.focus();
    }
  }, [isFocused]);

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <Accordion
      data-testid={testid}
      color={color}
      onClick={toggle}
      onKeyDown={toggle}
      tabIndex={0}
      role="button"
    >
      <Top>
        <Label size="sm" weight="bold" variant="muted">
          {`${number}`.padStart(2, '0')}
        </Label>
        <Caret collapsed={collapsed} height={10} width={19} />
      </Top>
      <Content>
        <AnimatePresence initial={false}>
          {!collapsed && (
            <motion.div
              key={imageSrc}
              id={imageSrc}
              aria-hidden
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={variants}
              transition={transitions}
              style={{ overflow: 'hidden' }}
            >
              <ImageContainer>
                <ImageWrap height={imageHeight} width={imageWidth}>
                  <Image
                    src={imageSrc}
                    alt={imageAlt}
                    height={imageHeight}
                    width={imageWidth}
                    layout="fixed"
                  />
                </ImageWrap>
              </ImageContainer>
            </motion.div>
          )}
        </AnimatePresence>
        <ToggleButton
          aria-expanded={!collapsed}
          aria-controls={title}
          onKeyDown={handleKeyDown}
          ref={toggleButtonRef}
          onFocus={handleFocus}
        >
          <Description>
            <Title size="md">{title}</Title>
            <Title size="xs" variant="muted" lineHeight="titleXs" weight="regular">
              {text}
            </Title>
          </Description>
        </ToggleButton>
        <AnimatePresence initial={false}>
          {!collapsed && (
            <motion.div
              key={title}
              id={title}
              aria-hidden={collapsed}
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={variants}
              transition={transitions}
              style={{ overflow: 'hidden' }}
            >
              <Items>
                {contentItems.map((item) => (
                  <Item key={item}>
                    <CircleCheckIcon style={{ alignSelf: 'start', marginTop: pxToRem(3) }} />
                    <Text size="lg" weight="bold">
                      {item}
                    </Text>
                  </Item>
                ))}
              </Items>
            </motion.div>
          )}
        </AnimatePresence>
      </Content>
    </Accordion>
  );
};

const Accordion = styled(motion.div)<{ color: keyof Colors }>`
  cursor: pointer;
  min-height: ${pxToRem(256)};
  ${({ color }) =>
    color &&
    css`
      border-top: 3px solid ${getColor(color)};
    `};
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${pxToRem(14)} 0 ${pxToRem(8)};
`;

const Content = styled.div`
  position: relative;
`;

const Description = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: ${pxToRem(12)};
`;

const Items = styled.ul`
  padding-top: ${getSpace('normal2')};
  padding-bottom: ${pxToRem(88)};
  display: grid;
  grid-auto-flow: row;
  row-gap: ${getSpace('normal2')};
`;

const Item = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${pxToRem(10)};
`;

const ToggleButton = styled.button`
  background: transparent;
  border: 0;
  color: ${getColor('base900')};
  cursor: pointer;
  padding: 0;
  text-align: left;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: ${getSpace('medium1')};
  height: ${pxToRem(192)};
`;

const ImageWrap = styled.div<{ height: number; width: number }>`
  height: ${(p) => pxToRem(p.height)};
  width: ${(p) => pxToRem(p.width)};
  position: relative;
`;

const Caret = styled(CaretDownIcon)<{ collapsed: boolean }>`
  && {
    transform-origin: center;
    transform: translateY(-2px) rotate(180deg);
    transition: transform ${getDuration('shorter')}ms ${getTransition('easeInOut')};

    ${({ collapsed }) =>
      collapsed &&
      css`
        transform: translateY(-2px) rotate(0deg);
        transition: transform ${getDuration('shorter')}ms ${getTransition('easeInOut')};
      `}
  }
`;

export default ColorAccordion;
