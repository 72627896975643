import { ImageHeaderSection } from '@yoweb/ui/components/sections/ImageHeaderSection';
import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import { replaceNullWithUndefined } from '@yoweb/utils/replaceNullWithUndefined';
import { Container } from '@yoweb/ui/components/containers/Container';
import { InView } from '@yoweb/ui/components/AnimateIn';
import { type ImageHeaderSliceResponse, requiredPrimaryFields } from './ImageHeaderSliceTypes';

type ImageHeaderSliceProps = {
  atBottom?: boolean;
  slice: ImageHeaderSliceResponse;
};

const ImageHeaderSlice = ({ slice, ...rest }: ImageHeaderSliceProps) => {
  const { image, title, subtitle, scrollToSectionAnchor } = replaceNullWithUndefined(slice.primary);

  const commonProps = {
    imageAlt: image?.alt ?? '',
    imageSrc: image?.url ?? '',
    scrollToSectionAnchor,
    subtitle: subtitle ?? '',
    title: title ?? '',
  };

  return (
    <InView>
      {(isInView) => (
        <Container>
          <ImageHeaderSection {...commonProps} {...rest} isInView={isInView} />
        </Container>
      )}
    </InView>
  );
};

export default withPrismicGuardian(ImageHeaderSlice, {
  primaryRequired: requiredPrimaryFields,
});
