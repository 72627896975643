import { isObjectLike } from './isObjectLike';

type RecursivelyReplaceNullWithUndefined<T> = T extends null
  ? undefined
  : T extends Date
    ? T
    : {
        [K in keyof T]: T[K] extends (infer U)[]
          ? RecursivelyReplaceNullWithUndefined<U>[]
          : RecursivelyReplaceNullWithUndefined<T[K]>;
      };

/**
 * Recursively replaces all instances of null with undefined
 * Skips object classes (that have a `.__proto__.constructor`).
 */
export function replaceNullWithUndefined<T extends object>(
  obj: T,
): RecursivelyReplaceNullWithUndefined<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newObj: any = {};

  Object.keys(obj).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: any = (obj as any)[key];

    newObj[key as keyof T] =
      // eslint-disable-next-line no-nested-ternary
      value === null
        ? undefined
        : value && isObjectLike(value) && value.__proto__.constructor === Object
          ? replaceNullWithUndefined(value)
          : value;
  });

  return newObj;
}
