import {
  AccordionColorGroupsSection,
  type AccordionGroup,
} from '@yoweb/ui/components/sections/AccordionColorGroupsSection';
import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import { Container } from '@yoweb/ui/components/containers/Container';
import { AnimateWhenInView } from '@yoweb/ui/components/AnimateIn';
import {
  type AccordionColorGroupsSectionSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './AccordionColorGroupsSectionSliceTypes';

type AccordionColorGroupsSectionSliceProps = {
  isInView?: boolean;
  slice: AccordionColorGroupsSectionSliceResponse;
};

const AccordionColorGroupsSectionSlice = ({
  slice,
}: AccordionColorGroupsSectionSliceProps): JSX.Element => {
  const groups: AccordionGroup[] =
    slice?.items.map(
      ({ color, contentItems = [], image, imageHeight, imageWidth, text, title }, index) =>
        ({
          color,
          // @ts-ignore type doesn't recognize the text field
          contentItems: contentItems.map((paragraph) => paragraph?.text),
          imageAlt: image.alt,
          imageHeight: imageHeight ?? image.dimensions?.height,
          imageSrc: image.url,
          imageWidth: imageWidth ?? image.dimensions?.width,
          number: index,
          text,
          title,
        }) as AccordionGroup,
    ) ?? [];

  return (
    <AnimateWhenInView>
      <Container>
        <AccordionColorGroupsSection data-testid="accordion-color-groups-slice" groups={groups} />
      </Container>
    </AnimateWhenInView>
  );
};

export default withPrismicGuardian(AccordionColorGroupsSectionSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
