import type { RichTextField, ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const AccordionColorGroupsSectionSliceType = 'accordion_color_groups_section_slice';

// Required fields in slice
export const requiredPrimaryFields = [] as const; // Array of all required primary fields.
export const requiredItemsFields = ['color', 'image', 'text', 'title'] as const; // Array of all required items fields.

type Item = {
  color: string;
  contentItems?: RichTextField;
  image: ImageField;
  imageHeight?: number;
  imageWidth?: number;
  text: string;
  title: string;
};

export type AccordionColorGroupsSectionSliceResponse = PrismicSlice<
  typeof AccordionColorGroupsSectionSliceType,
  null,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;
