import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const CircleCheckIcon = (props: IconProps) => (
  <IconBase viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zm-6.212-2.669a.75.75 0 00-1.063-1.058l-6.42 6.454-.774-.757-1.737-1.785a.75.75 0 00-1.075 1.047l1.744 1.791.013.014 1.314 1.282a.75.75 0 001.056-.008l6.942-6.98z"
      fill="currentcolor"
    />
  </IconBase>
);

export default CircleCheckIcon;
