import { chunk } from 'lodash-es';
import { useState, type KeyboardEvent } from 'react';
import styled from 'styled-components';
import type { Colors } from 'styled-components';

import { media } from '@yoweb/ui/styles/utils';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import ColorAccordion from '@yoweb/ui/components/ColorAccordion';
import { RenderOn } from '../RenderOn/RenderOn';

export type AccordionGroup = {
  color: keyof Colors;
  contentItems: string[];
  imageAlt: string;
  imageHeight: number;
  imageSrc: string;
  imageWidth: number;
  number: number;
  text: string;
  title: string;
};

export type AccordionColorGroupsSectionProps = {
  children?: ReactNode;
  groups: AccordionGroup[];
  isInView?: boolean;
} & DataTestId;

enum ScreenTypeOrder {
  Mobile = 1,
  Tablet = 5,
  Desktop = 3,
}

const groupAndTranspose = (array: AccordionGroup[], columnCount: number): AccordionGroup[][] => {
  const chunked = chunk(array, Math.ceil(array.length / columnCount));

  // Transpose array to get correct ordering
  for (let groupIndex = 0; groupIndex < chunked.length; groupIndex++) {
    for (let itemIndex = 0; itemIndex < groupIndex; itemIndex++) {
      const tmp = chunked[groupIndex][itemIndex];
      chunked[groupIndex][itemIndex] = chunked[itemIndex][groupIndex];
      chunked[itemIndex][groupIndex] = tmp;
    }
  }

  // If the matrix to be transposed is not square, remove all empty rows.
  return chunked.map((group) => group.filter((item) => !!item)).filter((group) => group.length > 0);
};

export const AccordionColorGroupsSection = ({
  groups,
  ...props
}: AccordionColorGroupsSectionProps): JSX.Element => {
  const [focusedAccordion, setFocusedAccordion] = useState(-1);
  const testid = props['data-testid'] ?? 'accordion-color-groups-section';

  // Used to fix the tab order of the accordions
  const accordionKeyboardHandler = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      if (event.shiftKey && focusedAccordion > 0) {
        event.preventDefault();
        setFocusedAccordion(focusedAccordion - 1);
      } else if (focusedAccordion < groups.length - 1) {
        event.preventDefault();
        setFocusedAccordion(focusedAccordion + 1);
      }
    }
  };

  /**
   * Grid should have:
   * 1 column - mobile
   * 2 columns - tablet
   * 3 columns - desktop
   */
  const generateAccordions = (screenTypeOrder: ScreenTypeOrder) => {
    const orderedAccordions = groupAndTranspose(groups, screenTypeOrder);
    const id = screenTypeOrder === ScreenTypeOrder.Desktop ? '' : `-${screenTypeOrder}`;
    return (
      <AccordionContainer id={`accordion-section${id}`}>
        {orderedAccordions.map((accordionGroup, groupIndex) => (
          <StyledAccordionGroup key={`accordion-group${id}-${groupIndex}`}>
            {accordionGroup.map((accordion) => (
              <ColorAccordion
                data-testid={`color-accordion${id}`}
                {...accordion}
                key={`${accordion.title}${id}`}
                number={accordion.number + 1}
                isFocused={accordion.number === focusedAccordion}
                onKeydown={accordionKeyboardHandler}
                onFocus={() => onFocus(accordion.number)}
              />
            ))}
          </StyledAccordionGroup>
        ))}
      </AccordionContainer>
    );
  };

  const onFocus = (index: number) => setFocusedAccordion(index);

  return (
    <div data-testid={testid}>
      <RenderOn.MobileOnly>{generateAccordions(ScreenTypeOrder.Mobile)}</RenderOn.MobileOnly>
      <RenderOn.TabletOnly>{generateAccordions(ScreenTypeOrder.Tablet)}</RenderOn.TabletOnly>
      <RenderOn.Desktop>{generateAccordions(ScreenTypeOrder.Desktop)}</RenderOn.Desktop>
    </div>
  );
};

const AccordionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${getSpace('normal2')};
  margin-top: ${getSpace('normal3')};

  ${media.md`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${media.lg`
    grid-template-columns: repeat(3, 1fr);
  `};
`;

const StyledAccordionGroup = styled.div`
  align-self: flex-start;
  display: grid;
  grid-template-rows: auto;
`;
