import Image from 'next/legacy/image';
import styled from 'styled-components';

import { CircleArrowIcon } from '@yoweb/ui/components/Icon/CircleArrowIcon';
import { Title } from '@yoweb/ui/components/typography';
import { pxToRem, media } from '@yoweb/ui/styles/utils';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import AnimateIn, { StaggerElements, STAGGER_DELAY } from '@yoweb/ui/components/AnimateIn';
import { useTranslation } from '@yoweb/next-i18next';

// store header heights
const HEADER_HEIGHT = 76;
const HEADER_HEIGHT_DESKTOP = 130;

export type ImageHeaderSectionProps = {
  children?: ReactNode;
  imageAlt: string;
  imageSrc: string;
  isInView?: boolean;
  scrollToSectionAnchor?: string;
  subtitle?: string | ReactNode;
  title: string;
} & DataTestId;

export const ImageHeaderSection = ({
  imageAlt,
  imageSrc,
  isInView = false,
  scrollToSectionAnchor,
  subtitle,
  title,
  ...props
}: ImageHeaderSectionProps) => {
  const { t } = useTranslation('common');
  const testid = props['data-testid'] ?? 'image-header-section-slice';

  return (
    <HeroContainer data-testid={testid}>
      <AnimateIn isVisible={isInView}>
        <ImageContainer>
          <Image
            src={imageSrc}
            alt={imageAlt}
            objectFit="contain"
            objectPosition="bottom"
            height={336}
            width={402}
            priority
          />
        </ImageContainer>
      </AnimateIn>
      <TitleContainer>
        <StaggerElements isInView={isInView} initialDelay={STAGGER_DELAY}>
          <Title size={{ _: 'lg', md: 'xl', lg: 'xxl' }} textAlign="center">
            {title}
          </Title>
          <Title
            lineHeight="titleXs"
            size="xs"
            style={{ maxWidth: pxToRem(600) }}
            textAlign="center"
            weight="regular"
          >
            {subtitle}
          </Title>
        </StaggerElements>
      </TitleContainer>

      {Boolean(scrollToSectionAnchor) && (
        <AnimateIn isVisible={isInView} delay={STAGGER_DELAY * 2}>
          <a href={scrollToSectionAnchor} aria-label={t('anchorToContent')}>
            <CircleArrowIcon height={pxToRem(48)} width={pxToRem(48)} />
          </a>
        </AnimateIn>
      )}
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto auto;
  justify-items: center;
  max-width: ${pxToRem(648)};
  margin: 0 auto;
  padding: ${pxToRem(104 - HEADER_HEIGHT)} 0 ${getSpace('medium1')};
  text-align: center;

  ${media.md`
    padding: ${pxToRem(152 - HEADER_HEIGHT_DESKTOP)} 0 ${getSpace('large1')};
  `};

  ${media.lg`
    max-width: ${pxToRem(808)};
  `};
`;

const ImageContainer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  margin-bottom: ${getSpace('medium3')};
  max-width: ${pxToRem(3123)};
  position: relative;
  width: 100%;

  ${media.md`
    max-width: ${pxToRem(402)};
  `};
`;

const TitleContainer = styled.div`
  display: grid;
  justify-items: center;
  margin-bottom: ${getSpace('medium1')};
  row-gap: ${getSpace('normal1')};

  ${media.md`
    row-gap: ${getSpace('normal2')};
  `};
`;
