import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export function CircleArrowIcon(props: IconProps) {
  return (
    <IconBase viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.75 17a.75.75 0 00-1.5 0v12.19l-3.72-3.721a.75.75 0 00-1.061 1.06l5 5.001a.748.748 0 001.061 0l5.001-5a.75.75 0 00-1.06-1.061l-3.721 3.72V17z"
        fill="#1A1A1A"
      />
      <rect
        x={0.5}
        y={0.5}
        width={47}
        height={47}
        rx={23.5}
        stroke="#000"
        strokeOpacity={0.75}
        fill="none"
      />
    </IconBase>
  );
}

export default CircleArrowIcon;
