import type { ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const ImageHeaderSliceType = 'image_header_slice';

// Default variant required fields
export const requiredPrimaryFields = ['image', 'subtitle', 'title'] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  image: ImageField;
  scrollToSectionAnchor?: string;
  subtitle: string;
  title: string;
};

export type ImageHeaderSliceResponse = PrismicSlice<
  typeof ImageHeaderSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Primary>
>;
